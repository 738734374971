import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { SeoHead } from '../../containers/components';
import { PageBlog } from '../../containers/pages/page-blog';
import { getPrefooterTypeData } from '../../services/bestCitiesByType';
import { getFooterCache, getNavBarCache } from '../../services/cache';
import { StrapiApi } from '../../services/strapiApi';
import {
  FooterSectionType,
  NavigationBarType,
  PageArticleType,
  PageBaseProps,
  PageBlog as PageBlogType,
  PageSectionType,
  PageSeo,
  PreFootersType,
} from '../../types';

interface PageProps {
  navBarSection: PageSectionType<NavigationBarType>;
  footerSection: PageSectionType<FooterSectionType>;
  preFooterType: PreFootersType;
  articles: PageArticleType[];
  allArticles: PageArticleType[];
  seo: PageSeo;
  page: PageBlogType;
}

export default function Page(props: PageProps): JSX.Element {
  return (
    <>
      <SeoHead title={props.seo?.title} description={props.seo?.description} />
      <PageBlog
        navBarSection={props.navBarSection}
        footerSection={props.footerSection}
        preFooterType={props.preFooterType}
        articles={props.articles}
        allArticles={props.allArticles}
        page={props.page}
        overrideLogoUrl={'/blog'}
      />
    </>
  );
}

export async function getStaticProps({
  locale,
}: {
  locale: string;
}): Promise<PageBaseProps> {
  const navBarSection = await getNavBarCache();
  const footer = await getFooterCache();
  const pageBlog = await StrapiApi.single_types.pageBlog('fr');
  const allArticles = await StrapiApi.collection_types.allArticles([], 'fr');
  const articles = await StrapiApi.collection_types.getArticles(
    'fr',
    pageBlog.sliderArticles.data.map((i) => i.attributes.slug),
    6
  );

  return {
    props: {
      ...(await serverSideTranslations(locale)),
      navBarSection: navBarSection,
      footerSection: footer,
      articles,
      allArticles,
      preFooterType: getPrefooterTypeData(),
      seo: pageBlog.seo,
      page: pageBlog,
    },
  };
}
