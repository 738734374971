import React from 'react';
import tw from 'tailwind-styled-components';
import { PreFooterSectionItem, PreFootersType } from '../../../types';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';

interface PreFooterTypesSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: PreFootersType;
}

interface PreFooterProps {
  title: string;
  items: PreFooterSectionItem[];
  variables?: PageVariables;
  isFullScreen?: boolean;
}

function PreFooter(props: PreFooterProps): JSX.Element {
  const { title, items, variables } = props;

  return (
    <Row>
      <Title>{textCompiler(title, variables)}</Title>
      <LinksContainer>
        {items.map((item, i) => (
          <Link key={i} href={item.url}>
            {item.name}
          </Link>
        ))}
      </LinksContainer>
    </Row>
  );
}

export function PreFooterTypesSection(
  props: PreFooterTypesSectionProps
): JSX.Element {
  const { data, variables } = props;

  return (
    <Main>
      <PreFooter
        title={'Recherches fréquentes VTC'}
        items={data.vtc.items}
        variables={variables}
      />
      {/* <PreFooter
        title={'Recherches fréquentes Taxi'}
        items={data.taxi.items}
        variables={variables}
      />
      <PreFooter
        title={'Recherches fréquentes Taxi moto'}
        items={data.taxiMoto.items}
        variables={variables}
      /> */}
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lm:px-0 
  px-5
  grid grid-cols-1
`;

const Row = tw.div`
  py-8
  px-8
  border-t
  col-span-1
`;

const Title = tw.p`
  text-base
`;

const LinksContainer = tw.div`
  grid
  lg:grid-cols-4
  grid-cols-1
`;

const Link = tw.a`
  text-sm
  mt-4
  text-darkGrey
  hover:underline 
  hover:text-blue
`;
