/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { isMobile } from 'react-device-detect';
import tw from 'tailwind-styled-components';
import { useLocalStorage } from '../../hooks';

export function MobileAppDownloadBanner(): JSX.Element | null {
  const [show, setShow] = useLocalStorage('showAppDownloadBanner', true);

  return show && isMobile ? (
    <a
      href={process.env.NEXT_PUBLIC_DOWNLOAD_APP_LINK}
      target='_blank'
      rel='noreferrer'
    >
      <Main>
        <TitleContainer>
          <Title>Telecharger notre application</Title>
          <Close
            onMouseDown={(e: any) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              setShow(false);
            }}
          >
            {'X'}
          </Close>
        </TitleContainer>
        <Description>
          Allocab est le 1er réseau de VTC en France ! Commandez en toute
          sécurité
        </Description>
      </Main>
    </a>
  ) : null;
}

//  bg-black

const Main = tw.div`
  lg:hidden
  grid
bg-black
  px-4
  py-3
  w-screen
  absolute
  top-0
  z-50
`;

const TitleContainer = tw.div`
col-span-1
flex flex-row content-center justify-between
`;

const Title = tw.div`
text-sm
font-bold
text-white
underline
`;

const Close = tw.div`
text-base
font-bold
text-white
`;

const Description = tw.div`
text-white
text-xs
`;
