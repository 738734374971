/* eslint-disable indent */
import router from "next/router";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import tw from "tailwind-styled-components";
import { Swiper } from "../../components";
import { PageLayout } from "../../components/Layout/PageLayout";
import { MobileAppDownloadBanner } from "../../components/MobileAppDownloadBanner/MobileAppDownloadBanner";
import { renderSection } from "../../services/section";
import { getStrapiMediaUrl } from "../../services/strapiApi";
import {
  FooterSectionType,
  NavigationBarType,
  PageArticleType,
  PageBlog as PageBlogType,
  PageSectionType,
  PreFootersType,
} from "../../types";
import {
  ArticleCard,
  BlogHeader,
  CategoryBlock,
  NewsSlider,
  SwiperArticleCard,
} from "../components";
import { PreFooterTypesSection } from "../components/Sections/PreFooterTypesSection";

interface PageBlogProps {
  navBarSection: PageSectionType<NavigationBarType>;
  footerSection: PageSectionType<FooterSectionType>;
  preFooterType?: PreFootersType;
  articles: PageArticleType[];
  allArticles: PageArticleType[];
  page: PageBlogType;
  overrideLogoUrl?: string;
}

export function PageBlog(props: PageBlogProps): JSX.Element {
  const {
    navBarSection,
    footerSection,
    preFooterType,
    articles,
    page,
    allArticles,
    overrideLogoUrl,
  } = props;

  const [article1, article2, article3, article4, article5, article6] = articles;

  function renderArticleCard(
    article: PageArticleType,
    hideCoverImage = false,
    isFull = true
  ): JSX.Element | null {
    if (!article) return null;
    return (
      <ArticleCard
        className={isFull ? "lg:h-full" : ""}
        isLarge
        article={article}
        hideCoverImage={hideCoverImage}
        onClick={() => router.push(`/blog/article/${article.slug}`)}
      />
    );
  }

  return (
    <PageLayout>
      {renderSection(
        navBarSection,
        undefined,
        true,
        undefined,
        undefined,
        overrideLogoUrl
      )}
      <MobileAppDownloadBanner />
      <Main>
        <BlogHeader title={page.title} allArticles={allArticles} />
        <Categories>
          {page.categoriesToDisplay.data.map((category, i) => (
            <CategoryBlock category={category.attributes} key={i} />
          ))}
        </Categories>
        <SwiperSection
          $bgUrl={getStrapiMediaUrl(page.sliderBackground.data.attributes)}
        >
          <Swiper autoplay>
            {page.sliderArticles.data.map((article) => {
              return (
                <SwiperSlide key={article.id} className={"w-full h-4/5"}>
                  <SwiperArticleCard
                    article={article.attributes}
                    onClick={() =>
                      router.push(`/blog/article/${article.attributes.slug}`)
                    }
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SwiperSection>
        <Articles>
          <Grid1>
            {renderArticleCard(article1, isMobile ? false : true)}
            {renderArticleCard(article2, isMobile ? false : true)}
          </Grid1>
          <Grid1Tablette>
            {renderArticleCard(article1, false)}
            {renderArticleCard(article2, false)}
            <NewsContainer>
              <NewsSlider news={page.blogNews} />
            </NewsContainer>
          </Grid1Tablette>
          <div className="col-span-1">
            <NewsSlider news={page.blogNews} />
          </div>
          {renderArticleCard(article3, false)}
          {renderArticleCard(article4, false, false)}
          {renderArticleCard(article5, false, false)}
          {renderArticleCard(article6, false, false)}
        </Articles>
      </Main>
      {preFooterType && <PreFooterTypesSection data={preFooterType} />}
      {renderSection(footerSection)}
    </PageLayout>
  );
}

const Main = tw.div`
  flex
  flex-col
  w-full
  pb-20
`;

const Categories = tw.div`
  flex
  flex-row
  overflow-scroll
  lg:gap-4
  lg:mx-36
  py-6
  border-t
  border-b
  border-gray-300
  justify-center
  scrollbar-hide
`;

const Grid1 = tw.div`
  grid-cols-1
  gap-4
  lg:gap-4
  hidden
  lg:grid
`;

const Grid1Tablette = tw.div`
  grid
  grid-cols-1
  lg:grid-cols-2
  col-span-1
  sm:col-span-2
  gap-4
  lg:gap-4
  lg:hidden
`;

const NewsContainer = tw.div`
  hidden
  col-span-1
  xl:grid
`;

const SwiperSectionBase = tw.div`
  flex
  flex-row
  lg:px-48
  px-4
  lg:py-16
  py-16
  h-216c
  max-h-216
  lg:h-192
  lg:max-h-192
  my-16
`;

const SwiperSection = styled(SwiperSectionBase)<{ $bgUrl: string }>`
  ${(props: { $bgUrl: string }) => {
    return `background-image: url(${props.$bgUrl});`;
  }}

  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Articles = tw.div`
  px-4
  grid
  grid-cols-1
  lg:grid-cols-2
  xl:grid-cols-3
  gap-8
  xl:pb-8
  lg:px-36
`;
